.vgs-organization-switcher {
  padding-top: 4px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid theme('borderColor.neutral.200');
    width: 360px;

    &-content {
      padding: 0;
    }
  }

  .ant-menu {
    border: none;
    max-height: 230px;
    overflow: auto;

    &-title-content {
      overflow: hidden;
    }

    &-item.vgs-organization-row {
      align-items: center;
      display: flex;
      color: theme('borderColor.neutral.700');
      height: initial;
      margin: 0;
      overflow: auto;
      padding: 1rem 1.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid theme('borderColor.neutral.200');
        margin-bottom: 0;
      }

      &:hover:not(.disabled)  {
        background: theme('backgroundColor.primary.100');
      }

      &.disabled {
        cursor: not-allowed;

        .vgs-organization-label {
          color: theme('textColor.neutral.700')
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          height: 100%;
          width: 2px;
          background: theme('backgroundColor.primary.500');
        }
      }

      .vgs-organization-label {
        @apply tw-leading-normal;

        color: theme('textColor.primary.500');
      }
    }
  }
}
