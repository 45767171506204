$neutral-650: #4a5e68;
$tooltip-wrapper-width: 230px;

.recharts-tooltip-wrapper:focus-visible {
  outline: none;
}

.tooltip-wrapper {
  width: fit-content;
  max-width: $tooltip-wrapper-width;
  height: auto;
  border: 1px solid $neutral-650 !important;
  border-radius: 4px;
  background: #f9fafb;
  word-break: keep-all;
}

.tooltip-wrapper {
  position: relative;
  padding: 8px;
}

.tooltip-wrapper > span {
  font-weight: 600;
}

.tooltip-data__content .status-color {
  display: block;
  height: 6px;
  width: 6px;
  background-color: #45597e;
  border-radius: 50%;
  margin-bottom: 0;
}

.tooltip-data__content {
  word-break: keep-all;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.tooltip__content div {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.status-color__pending {
  background-color: #7a0099 !important;
}
.status-color__approved {
  background-color: #2fc29f !important;
}
.status-color__declined {
  background-color: #cc6e00 !important;
}

.card-data__content {
  margin-left: 4px;
  font-weight: bold;
}
