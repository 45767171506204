.transfer-card {
  position: relative;
  margin-bottom: 2rem;

  &-label {
    position: absolute;
    top: -14px;
    padding: 0 .5rem;
    background: theme('colors.white');
  }
}

.code-block {
  max-height: 300px;
  background: theme('colors.neutral.100');
  padding: 1rem;
  border-radius: 8px;
}
