.widget {
  width: 100%;

  &-content {
    max-width: 675px;
  }

  &-actions {
    height: var(--widget-footer-height);
  }

  &.ant-card {
    margin: auto;

    .ant-card-body,
    .ant-card-content {
      height: 100%;
      padding: 0;
    }
  }

  h2.tw-header {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    line-height: 130%;
  }
}
