$text-default-font-size: 0.875rem;
$primary-550: #0a51e1;
$primary-650: #0044cc;
$switch-tab-border-top: 4px solid $primary-550 !important;
$neutral-700: #40545f;

@mixin switch-tab-border-bottom-none {
  padding: 0;
  content: "";
  width: 286px;
  display: block;
  height: 2px;
  position: absolute;
  background: white;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

main {
  height: 1080px;
  font-family: "Averta", Inter, -apple-system, system-ui;
}

.tab-switcher__container {
  @include flex-row;
}

.tab-switcher__content {
  width: 286px;
  height: 105px;
  padding: 1.25rem 1.4375rem;
  font-size: $text-default-font-size;
  line-height: 1.0625rem;
  color: #1e2933 !important;
  h3 {
    margin-bottom: 8px;
    letter-spacing: 0.05rem;
  }
}

.active-tab {
  border-top: $switch-tab-border-top;
  cursor: unset;
  & h3 {
    color: $primary-550 !important;
  }
  & span {
    color: $primary-550 !important;
  }
}

.tab-switcher__content {
  & h3 {
    font-size: 2rem;
    font-style: normal;
    line-height: 2.5rem;
  }
}
.tab-switcher:not(.active-tab):hover  {
  font-size: $text-default-font-size;
  cursor: pointer;
}

.usage-reporting__content {
  padding: 2rem 1.5rem;
  height: auto;
  border: 1px solid #e1e6ed;

  .ant-btn {
    padding: 6px 13px;
    height: fit-content;
    font-size: $text-default-font-size;
    line-height: 17px;
  }
}

.usage-reporting__opened-tab {
  display: grid;
  grid-template-rows: 1fr 2fr;
  gap: 32px;
  min-height: 350px;
}

.data-type-switcher__container {
  @include flex-row;
  gap: 1rem;
  height: fit-content;
}

.card-wrapper {
  @include flex-row;
  gap: 1rem;
  height: fit-content;
}

.card-content {
  @apply tw-flex tw-w-full tw-justify-between;
}

.card-data__wrapper {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: end;
}

.status-color {
  height: 6px;
  width: 6px;
  margin-bottom: 7px;
  margin-right: 8px;
  background-color: #45597e;
  border-radius: 50%;
  justify-self: bottom;
  &__pending {
    background-color: #7a0099 !important;
  }
  &__approved  {
    background-color: #2fc29f !important;
  }
  &__declined {
    background-color: #cc6e00 !important;
  }
}

.card-data_status-icon {
  height: 8px;
  width: 16px;
  margin-bottom: 7px;
  margin-right: 8px;
  justify-self: bottom;
}

.data_wrapper-text {
  display: flex;
  flex-direction: column;
  & span {
    width: fit-content;
  }
}

.headline-container {
  display: flex;
  align-items: center;
}

.card-status__list {
  display: flex;
  gap: 6rem;
}

.diff-popover {
  background-color: #d2dae4;
  border-radius: 1rem;
  margin-left: 0.5rem;
  height: fit-content;
  padding: 0 0.5rem;
}

.transaction-stat {
  height: fit-content;
}

.transaction-count {
  font-size: 1.5rem;
  color: $neutral-700 !important
}

.usage-reporting__chart {
  position: relative;
  min-height: 250px;
}

.tab-switcher {
  position: relative;
  border: 1px solid #d2dae4;
  &::after {
    @include switch-tab-border-bottom-none;
  }
  &:hover::after {
    @include switch-tab-border-bottom-none;
  }
}

.active-tab::after {
  @include switch-tab-border-bottom-none;
}

.data-type-transaction, .data-type-value {
  border-top-right-radius: none;
  border-radius: 4px 0 0 4px !important;
}

.data-type-psp, .data-type-percentage {
  border-radius: 0 4px 4px 0 !important;
}

.indicator-wrapper {
  display: flex;
  align-self: flex-end;
}

.btn-active {
  background-color: $primary-650 !important;
  border-color: $primary-650 !important;
}

.data_wrapper-text {
  font-weight: 400;
  font-size: $text-default-font-size;
  text-transform: capitalize;
}
