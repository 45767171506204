// antd overrides

.ant-popover-inner-content {
    padding: 0;
}

.ant-popover {
    padding-top: 0;
}

.ant-popover-content {
    border: 1px solid #e1e6ed;
    border-radius: 2px;
}


.ant-popover-arrow-content {
    display: none;
}

.ant-select-selector {
    min-width: 209px;

}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    outline: none;
    border-radius: 4px;
    border-color: #e1e6ed ;
}

.ant-select-arrow .anticon > svg {
    stroke: #4A5E68;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f9fafb;
    border-radius: 4px;
}

.ant-select-item-option-active {
    font-size: 0.875rem;
    line-height: 170%;
    color: #000000;
}
.ant-btn-ghost_gray .more-filters__button {
    border-color: none;
}

.ant-btn {
    font-size: 0.75rem;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #F0F6FF;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: transparent;
    color: #000000;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #e1e6ed!important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none;
    box-shadow: none;
}
.ant-select-dropdown, .ant-select-item {
    line-height: 22px!important;
}


.filter-panel {
    @apply tw-flex tw-items-center tw-gap-4;
}

.enabled-filters-circle {
    position: absolute;
    right: -10px;
    top: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    text-align: center;
    background: #145ff5;
    font-size: 12px;
    line-height: 15px;
}

.filter-icon {
    margin-left: 12px;
}

.wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 20px 16px 24px 16px;
    font-family: 'Averta', sans-serif;

    & > div {
        display: flex;
        flex-direction: column;
    }
}

.filter-selector {
    margin: 8px 0 !important;
}

.selected-filters {
    margin: 8px 6px;
}

.button-row {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button {
        font-size: 12px;
    }
}

.cancel-btn {
    font-size: 12px;
    padding: 0 !important;
    margin-right: 24px;
}

.more-filters__button {
    font-family: 'Averta', sans-serif;
    display: flex !important;
    color: #4A5E68!important;
    line-height: 170%;
    font-weight: 400;
    font-size: 0.875rem;
    border-color: #E1E6ED!important;
    background: #F9FAFB;
}

.tab-switcher__content > h3, .tab-switcher__content > span {
    color: #40545F;
}


.usage-report-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.usage-reporting__container {
    margin-bottom: 24px;
}

.download-btn {
    font-family: 'Averta', sans-serif;
    display: flex;
    align-items: center;
}

.anticon svg {
    display: flex !important;
    align-items: center;
}
