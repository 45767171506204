.ant-popover-content > .ant-popover-arrow {
  display: none;
}

.ant-popover-inner {
  border-radius: 8px;
  border: 1px solid theme('colors.neutral.200');
  overflow: hidden;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5em 1rem;
  clear: both;
  font-weight: 400;
  color: theme('colors.neutral.700');
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    color: theme('colors.neutral.900');
    text-decoration: none;
    background-color: #f9fafb;
  }
}
