@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/base";
@import "styles/antd/popover";

@import "components/organisms/header/header";
@import "components/organisms/delete-prompt/delete-prompt";
@import "components/organisms/organization-switcher/organization-switcher";
@import "components/organisms/vault-switcher/vault-switcher";
@import "components/templates/welcome-widget/welcome-widget";
@import "components/templates/transfers-widget/transfers-widget";
@import "components/templates/checkout-widget/checkout-widget";

@import "pages/dashboard/dashboard";

@import url(https://static.verygoodsecurity.com/fonts/Averta/very-good-security.min.css);
