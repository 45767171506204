$nav-sidebar-spacing: 100px;
$organization-and-vault-swicher-width: 320px;
$application-switcher-item-bg: theme('colors.primary.800') !important;
$application-switcher-item-active-bg: theme('colors.primary.500') !important;

.switcher-container div, .header{
  font-family: Inter,Averta, sans-serif !important;
  font-weight: 400;
  font-size: 0.875rem;
}

.vgs-switcher-icon {
  display: flex;
  height: 1.5rem;
  margin-top: 0.4375rem;
  align-items: end;
  margin-left: 0.5rem;
  color: white;
  & svg {
    width: 12px;
  }
}

.testie {
  font-weight: 500;
}

.header {
  background: theme('colors.primary.900');
  color: white !important;
  border-bottom: 1px solid theme('colors.neutral.200');
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  height: var(--header-height);
  z-index: 99;
  width: 100%;
}

.user-profile {
  @apply tw-flex tw-h-full tw-items-center;

  &-info {
    @apply tw-flex tw-items-center tw-cursor-pointer;

    max-width: 250px;
  }

  &-dropdown {
    .ant-popover-inner-content {
      padding: 0;
      width: 200px;
    }

    .ant-menu {
      border: none;
      padding: 0;
    }

    .ant-menu-inline .ant-menu-item {
      margin: 0;
      width: 100%;
    }

    .ant-menu-item:hover {
      color: initial;
      background: theme('colors.primary.100');
    }
  }
}



.nav-sidebar {
  margin-top: $nav-sidebar-spacing;
}

$switcher-text-color: 0.75rem;

.vgs-switcher-item {
  font-size: $switcher-text-color;
  padding: 1px 0;
}

.arrow-down-icon {
  color: white;
}

.application-switcher {
  .ant-menu-item-selected {
    background-color: $application-switcher-item-bg;
  }
  @apply tw-px-10;
  background-color: theme('colors.primary.800') !important;
  .active-menu-item {
    background-color: $application-switcher-item-active-bg;
    .ant-menu-title-content {
      color: white;
    }
    &:hover {
      background-color: $application-switcher-item-active-bg;
    }
  }
}


.navbar-brand {
  display: inline-block;
  padding-top: 0.2rem;
  padding-bottom: 0.125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  min-width: 21px;
}

.switcher-container {
  width: $organization-and-vault-swicher-width;
  align-items: center;
}

.organization-dropdown {
  line-height: 1.6;
}

.org-name {
  line-height: 1.599;
}
.organization-switcher-title {
  max-height: 20.5px;
}
